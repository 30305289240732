.square {
    aspect-ratio: 1 / 1;
    vertical-align: middle;
    text-align: center;
    position: relative;
    background-color: #FFCE9E;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square:nth-child(-n + 8):nth-child(even),
.square:nth-child(n + 8):nth-child(-n + 16):nth-child(odd),
.square:nth-child(n + 17):nth-child(-n + 24):nth-child(even),
.square:nth-child(n + 25):nth-child(-n + 32):nth-child(odd),
.square:nth-child(n + 33):nth-child(-n + 40):nth-child(even),
.square:nth-child(n + 41):nth-child(-n + 48):nth-child(odd),
.square:nth-child(n + 49):nth-child(-n + 56):nth-child(even),
.square:nth-child(n + 57):nth-child(-n + 64):nth-child(odd) {
    background-color: #D18B47;
}

.board {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.board-rotate {
    transform: rotate(180deg);
}

.square.selected {
    border: 3px solid yellow;
}

.token {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    display: inline-block;
    box-sizing: content-box;
}

.token.token-black {
    background-color: black;
    border: 5px solid #585858bd;
}

.token.token-white {
    background-color: white;
    border: 5px solid #dfdfdfbd;
}

.possibility {
    width: 40%;
    height: 40%;
    border-radius: 100%;
    display: inline-block;
    background-color: black;
    opacity: 0.6;
}

.col-label, .row-label {
    position: absolute;
    font-size: 11px;
}

.col-label {
    bottom: 3px;
    left: 6px;
}

.row-label {
    top: 3px;
    right: 6px;
}

.anti-rotate {
    transform: rotate(-180deg);
}

@media (max-width: 991px) {
    .board {
        margin-bottom: 20px;
    }
}